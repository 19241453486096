<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userInfo.name || userInfo.username }}
        </p>
        <span class="user-status">{{ userInfo.roleName }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'VIEW_PROFILE'}"
      link-class="d-flex align-items-center"
      :active="!!(currentRouteName === 'VIEW_PROFILE')"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ t(`routes.PROFILE`) }}</span>
    </b-dropdown-item>
    <b-dropdown-item 
      v-clipboard:copy="registerUrl"
      v-clipboard:success="onCopy"
    >
      <feather-icon
        icon="LinkIcon"
        size="16"
        class="mr-50"
      />
      <span>
        {{ t(`agent.register_link`) }}
      </span>
    </b-dropdown-item>
    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center text-danger"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ t('buttons.logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, 
  BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    currentRouteName() {
      return this.$route.name
    },
    registerUrl() {
      return `${
        this.userInfo.registration_url ||
        process.env.VUE_APP_CLIENT_URL
      }/register?agent_id=${this.userInfo.agentId || ''}`
    },
  },
  methods: {
    ...mapActions(['logout']),
    onCopy() {
      this.$bvToast.toast(`${this.$t('buttons.copied')}`, {
        variant: 'success',
        title: 'Success',
      })
    },
  },
}
</script>
